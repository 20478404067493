import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ViewTitleService } from './view-title.service';

@Component({
    selector: 'in-view-title',
    templateUrl: './view-title.component.html',
    styleUrls: ['./view-title.component.scss']
})
export class ViewTitleComponent implements OnInit, OnDestroy {
    title: string;
    subtitle: SafeHtml;

    private subscriptionTitle: Subscription;
    private subscriptionSubtitle: Subscription;

    constructor(private viewTitle: ViewTitleService) {}

    ngOnInit(): void {
        // Set title when it changes
        this.subscriptionTitle = this.viewTitle.title.subscribe(title => {
            this.setTitle(title);
        });
        // Set subtitle when it changes
        this.subscriptionSubtitle = this.viewTitle.subtitle.subscribe(
            subtitle => {
                this.setSubtitle(subtitle);
            }
        );
    }

    ngOnDestroy(): void {
        this.subscriptionTitle.unsubscribe();
        this.subscriptionSubtitle.unsubscribe();
    }

    setTitle(title: string): void {
        this.title = title;
    }

    setSubtitle(subtitle: SafeHtml): void {
        this.subtitle = subtitle;
    }
}
