import * as tslib_1 from "tslib";
import { NgZone, Optional, Provider, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { announceLogin, announceLogout } from '@infarm/auth';
import { Storage } from '../storage/storage';
import { Jwt } from './jwt';
import { isToken, jsonToToken, Token } from './token';
export var TOKEN_STORAGE_KEY = 'auth_token';
/**
 * NOTE: If a persistent storage for the token is not provided (such as localStorage, cookie, etc.),
 * the token will no longer be available on a page refresh.
 */
var Auth = /** @class */ (function () {
    function Auth(zone, jwt, storage) {
        this.zone = zone;
        this.jwt = jwt;
        this.storage = storage;
        // NOTE: We try to retrieve the token here so that the change observable has the right value in case the user is already authenticated
        this.tokenPromiseSource = new BehaviorSubject(this.token());
        this.tokenSource = this.tokenPromiseSource
            .asObservable()
            .pipe(mergeMap(function (token) {
            return token instanceof Promise ? token : Promise.resolve(token);
        }), distinctUntilChanged(tokenCompare));
        this.change = this.tokenSource; // tslint:disable-line: member-ordering
    }
    /**
     * Retrieve Jwt token from storage.
     * Can be used without injecting the service,
     * but be aware that it only retrieves the token from storage and it does not try to refresh the token.
     */
    Auth.tokenStatic = function (zone) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Storage.get(TOKEN_STORAGE_KEY, zone)];
                    case 1:
                        json = _a.sent();
                        return [4 /*yield*/, jsonToToken(json)];
                    case 2:
                        token = _a.sent();
                        return [2 /*return*/, token];
                }
            });
        });
    };
    /**
     * Retrieve Jwt token from storage
     */
    Auth.prototype.token = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, Auth.tokenStatic(this.zone)];
            });
        });
    };
    Auth.prototype.refreshJwtToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expiredToken, refreshedToken, storedToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.token()];
                    case 1:
                        expiredToken = _a.sent();
                        return [4 /*yield*/, this.jwt.refreshJwtToken(expiredToken.refreshToken)];
                    case 2:
                        refreshedToken = _a.sent();
                        storedToken = new Token(refreshedToken.accessToken, expiredToken.refreshToken);
                        return [2 /*return*/, this.storeToken(storedToken)];
                }
            });
        });
    };
    Auth.prototype.loginWithOauth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.jwt.tokenForOauth()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, this.storeToken(token)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, token];
                }
            });
        });
    };
    Auth.prototype.initializeOauth = function (googleClientId) {
        return this.jwt.initializeOauth(googleClientId);
    };
    /**
     * Remove Jwt token from storage and signal change.
     */
    Auth.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.storage.remove(TOKEN_STORAGE_KEY);
                this.tokenPromiseSource.next(null);
                announceLogout(true);
                return [2 /*return*/];
            });
        });
    };
    Auth.prototype.storeToken = function (token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storedToken, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.storage.set(TOKEN_STORAGE_KEY, isToken(token) ? token.serialize() : null);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, this.storage.get(TOKEN_STORAGE_KEY)];
                    case 1:
                        storedToken = _b.apply(_a, [_c.sent()]);
                        this.tokenPromiseSource.next(storedToken);
                        announceLogin(token ? token.accessToken : null);
                        return [2 /*return*/, storedToken];
                }
            });
        });
    };
    return Auth;
}());
export { Auth };
/**
 * Auth providers
 */
export function AUTH_PROVIDER_FACTORY(parentFactory, zone, jwt, storage) {
    return parentFactory || new Auth(zone, jwt, storage);
}
export var AUTH_PROVIDER = {
    // If there is already a Auth available, use that.
    // Otherwise, provide a new one.
    provide: Auth,
    useFactory: AUTH_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), Auth], NgZone, Jwt, Storage]
};
export function tokenCompare(previous, current) {
    return (previous === current ||
        (current instanceof Token && current.equals(previous)) ||
        (previous instanceof Token && previous.equals(current)));
}
