import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { authenticationErrorMessage } from './shared';
import { GoogleAuthProvider } from './google-auth-provider';
import { Token } from './token';
import { setAvatar } from '@infarm/auth';

@Injectable()
export class OauthService {
    // https://developers.google.com/identity/sign-in/web/reference
    auth2: any;
    constructor(
        private http: HttpClient,
        private googleAuthProvider: GoogleAuthProvider
    ) {}

    async initializeOauth(clientId: string) {
        this.auth2 = await this.googleAuthProvider.initializeAuthProvider(
            clientId
        );
    }

    async obtainOauthToken(): Promise<Token> {
        const googleCode = await this.obtainGoogleOnetimeCode();
        const backendState = await this.obtainStateFromBackend();
        return this.obtainAccessToken(googleCode, backendState);
    }

    async obtainGoogleOnetimeCode() {
        const tokenResponse = await this.auth2.grantOfflineAccess({
            prompt: 'select_account'
        });
        return tokenResponse.code;
    }

    async obtainStateFromBackend(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get('oauth/ext-google', {
                    headers: {
                        accept: 'application/json'
                    },
                    withCredentials: true
                })
                .subscribe(
                    data => {
                        resolve((data as any).state);
                    },
                    error => reject(error)
                );
        });
    }

    async obtainAccessToken(
        googleCode: string,
        backendState: string
    ): Promise<Token> {
        const encodedUri = `oauth/ext-google/authorized?code=${encodeURIComponent(
            googleCode
        )}&state=${encodeURIComponent(backendState)}&next=${encodeURIComponent(
            '/'
        )}`;
        return new Promise((resolve, reject) => {
            this.http
                .get(encodedUri, {
                    headers: {
                        accept: 'application/json'
                    },
                    withCredentials: true
                })
                .subscribe(
                    (data: any) => {
                        if (this.auth2 && this.auth2.isSignedIn.get()) {
                            const profile = this.auth2.currentUser
                                .get()
                                .getBasicProfile();
                            setAvatar(profile.getImageUrl());
                        }
                        resolve(
                            new Token(data.access_token, data.refresh_token)
                        );
                    },
                    () => reject(authenticationErrorMessage)
                );
        });
    }

    async refreshJwtToken(refreshToken: string): Promise<Token> {
        return new Promise((resolve, reject) => {
            this.http
                .get('/auth/refresh', {
                    headers: {
                        accept: 'application/json',
                        authorization: `Bearer ${refreshToken}`
                    },
                    withCredentials: true
                })
                .subscribe(
                    (data: any) =>
                        resolve(
                            new Token(data.access_token, data.refresh_token)
                        ),
                    reject
                );
        });
    }
}
