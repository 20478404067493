/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./view-title.component";
import * as i3 from "./view-title.service";
var styles_ViewTitleComponent = [i0.styles];
var RenderType_ViewTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewTitleComponent, data: {} });
export { RenderType_ViewTitleComponent as RenderType_ViewTitleComponent };
export function View_ViewTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "view-title"], ["id", "view-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "subtitle"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.subtitle; _ck(_v, 3, 0, currVal_1); }); }
export function View_ViewTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "in-view-title", [], null, null, null, View_ViewTitleComponent_0, RenderType_ViewTitleComponent)), i1.ɵdid(1, 245760, null, 0, i2.ViewTitleComponent, [i3.ViewTitleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewTitleComponentNgFactory = i1.ɵccf("in-view-title", i2.ViewTitleComponent, View_ViewTitleComponent_Host_0, {}, {}, []);
export { ViewTitleComponentNgFactory as ViewTitleComponentNgFactory };
