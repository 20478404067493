import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { authenticationErrorMessage } from './shared';
import { GoogleAuthProvider } from './google-auth-provider';
import { Token } from './token';
import { setAvatar } from '@infarm/auth';
var OauthService = /** @class */ (function () {
    function OauthService(http, googleAuthProvider) {
        this.http = http;
        this.googleAuthProvider = googleAuthProvider;
    }
    OauthService.prototype.initializeOauth = function (clientId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.googleAuthProvider.initializeAuthProvider(clientId)];
                    case 1:
                        _a.auth2 = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OauthService.prototype.obtainOauthToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var googleCode, backendState;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.obtainGoogleOnetimeCode()];
                    case 1:
                        googleCode = _a.sent();
                        return [4 /*yield*/, this.obtainStateFromBackend()];
                    case 2:
                        backendState = _a.sent();
                        return [2 /*return*/, this.obtainAccessToken(googleCode, backendState)];
                }
            });
        });
    };
    OauthService.prototype.obtainGoogleOnetimeCode = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tokenResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth2.grantOfflineAccess({
                            prompt: 'select_account'
                        })];
                    case 1:
                        tokenResponse = _a.sent();
                        return [2 /*return*/, tokenResponse.code];
                }
            });
        });
    };
    OauthService.prototype.obtainStateFromBackend = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http
                            .get('oauth/ext-google', {
                            headers: {
                                accept: 'application/json'
                            },
                            withCredentials: true
                        })
                            .subscribe(function (data) {
                            resolve(data.state);
                        }, function (error) { return reject(error); });
                    })];
            });
        });
    };
    OauthService.prototype.obtainAccessToken = function (googleCode, backendState) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var encodedUri;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                encodedUri = "oauth/ext-google/authorized?code=" + encodeURIComponent(googleCode) + "&state=" + encodeURIComponent(backendState) + "&next=" + encodeURIComponent('/');
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http
                            .get(encodedUri, {
                            headers: {
                                accept: 'application/json'
                            },
                            withCredentials: true
                        })
                            .subscribe(function (data) {
                            if (_this.auth2 && _this.auth2.isSignedIn.get()) {
                                var profile = _this.auth2.currentUser
                                    .get()
                                    .getBasicProfile();
                                setAvatar(profile.getImageUrl());
                            }
                            resolve(new Token(data.access_token, data.refresh_token));
                        }, function () { return reject(authenticationErrorMessage); });
                    })];
            });
        });
    };
    OauthService.prototype.refreshJwtToken = function (refreshToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http
                            .get('/auth/refresh', {
                            headers: {
                                accept: 'application/json',
                                authorization: "Bearer " + refreshToken
                            },
                            withCredentials: true
                        })
                            .subscribe(function (data) {
                            return resolve(new Token(data.access_token, data.refresh_token));
                        }, reject);
                    })];
            });
        });
    };
    return OauthService;
}());
export { OauthService };
