import { Injectable } from '@angular/core';
import { Token } from './token';
import { OauthService } from './oauth-service';
import { authenticationErrorMessage } from './shared';

@Injectable()
export class Jwt {
    constructor(private oauthService: OauthService) {}

    async tokenForOauth(): Promise<Token> {
        return new Promise<Token>(async (resolve, reject) => {
            try {
                const token = await this.oauthService.obtainOauthToken();
                resolve(token);
            } catch (error) {
                reject(authenticationErrorMessage);
            }
        });
    }

    async initializeOauth(clientId: string): Promise<any> {
        this.oauthService.initializeOauth(clientId);
    }

    async refreshJwtToken(refreshToken: string): Promise<Token> {
        return this.oauthService.refreshJwtToken(refreshToken);
    }
}
