import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { StorageModule } from '../storage/index';
import { ApiModule } from '../api/index';
import { Auth, AUTH_PROVIDER } from './auth';
import { AuthInterceptor } from './auth-interceptor';
import { Jwt } from './jwt';
import { OauthService } from './oauth-service';
import { GoogleAuthProvider } from './google-auth-provider';

export { AuthToken, Auth } from './auth';
export { Jwt } from './jwt';
export { jsonToToken, Token } from './token';

@NgModule({
    imports: [ApiModule, StorageModule],
    providers: [
        Jwt,
        OauthService,
        GoogleAuthProvider,
        AUTH_PROVIDER,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [Auth]
        }
    ]
})
export class AuthModule {}
