import * as tslib_1 from "tslib";
import { OauthService } from './oauth-service';
import { authenticationErrorMessage } from './shared';
var Jwt = /** @class */ (function () {
    function Jwt(oauthService) {
        this.oauthService = oauthService;
    }
    Jwt.prototype.tokenForOauth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var token, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.oauthService.obtainOauthToken()];
                                case 1:
                                    token = _a.sent();
                                    resolve(token);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_1 = _a.sent();
                                    reject(authenticationErrorMessage);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    Jwt.prototype.initializeOauth = function (clientId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.oauthService.initializeOauth(clientId);
                return [2 /*return*/];
            });
        });
    };
    Jwt.prototype.refreshJwtToken = function (refreshToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.oauthService.refreshJwtToken(refreshToken)];
            });
        });
    };
    return Jwt;
}());
export { Jwt };
