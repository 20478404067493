import * as tslib_1 from "tslib";
import { authenticationErrorMessage } from './shared';
var GoogleAuthProvider = /** @class */ (function () {
    function GoogleAuthProvider() {
        this.windowObject = window;
    }
    GoogleAuthProvider.prototype.initializeAuthProvider = function (clientId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            _this.windowObject.gapi.load('auth2', function () {
                                _this.windowObject.gapi.auth2.init({
                                    client_id: clientId,
                                    scope: 'profile email',
                                    hosted_domain: 'infarm.com',
                                    fetch_basic_profile: true
                                });
                                resolve(_this.windowObject.gapi.auth2.getAuthInstance());
                            });
                        }
                        catch (error) {
                            reject(authenticationErrorMessage);
                        }
                    })];
            });
        });
    };
    return GoogleAuthProvider;
}());
export { GoogleAuthProvider };
