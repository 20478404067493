<div class="main-layout" fxFlexFill>
    <mat-toolbar>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <in-view-title></in-view-title>
        </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
</div>
<div *ngIf="kompostVersion" class="kompostVersion">
    <span>Version: {{ kompostVersion }}</span>
</div>
