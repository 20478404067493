import { Injectable } from '@angular/core';
import { authenticationErrorMessage } from './shared';

@Injectable()
export class GoogleAuthProvider {
    private windowObject: any = window as any;

    async initializeAuthProvider(clientId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                this.windowObject.gapi.load('auth2', () => {
                    this.windowObject.gapi.auth2.init({
                        client_id: clientId,
                        scope: 'profile email',
                        hosted_domain: 'infarm.com',
                        fetch_basic_profile: true
                    });
                    resolve(this.windowObject.gapi.auth2.getAuthInstance());
                });
            } catch (error) {
                reject(authenticationErrorMessage);
            }
        });
    }
}
